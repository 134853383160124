@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Work Sans'), local('WorkSans-Regular'), url('/fonts/WorkSans-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Work Sans'), local('WorkSans-SemiBold'), url('/fonts/WorkSans-SemiBold.woff2') format('woff2');
}